import { useCallback, useEffect, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ConflictError, ConflictErrorType } from '@remento/types/error';
import Cookies from 'js-cookie';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMStepper } from '@/components/RMStepper/RMStepper';
import { RMText } from '@/components/RMText/RMText';
import { removeToast, toast } from '@/components/RMToast/RMToast';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { getQueryParam } from '@/hooks/useQueryParam';
import { getInputValue } from '@/modules/form/input';
import { getNewProjectPath, getSigninPath, RementoPage } from '@/modules/routing';
import { useServices } from '@/Services';
import { useSignOut, useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';
import { captureException } from '@/utils/captureException';

import { ProjectCheckoutDesktopLayout } from '../components/ProjectCheckoutLayout/ProjectCheckoutDesktopLayout';
import { ProjectCheckoutMobileLayout } from '../components/ProjectCheckoutLayout/ProjectCheckoutMobileLayout';
import { createProjectCheckoutForm } from '../project-checkout.form';
import {
  goToNextStep,
  goToStep,
  ProjectCheckoutManager,
  ProjectType,
  useCheckoutTotal,
  useCreateProjectCheckoutManager,
  useCurrentStep,
  useIsStepValid,
} from '../project-checkout.manager';
import { ProjectCheckoutManagerProvider } from '../project-checkout.manager.context';
import { PROJECT_CHECKOUT_STEPS } from '../project-checkout.steps';
import { getProjectCheckoutCoupons } from '../project-checkout.utils';
import { ProjectCheckoutServicesProvider } from '../project-checkout-services.context.';

import { ProjectCheckoutSummaryContainer } from './ProjectCheckoutSummary.container';

interface ContinueButtonContainerProps {
  manager: ProjectCheckoutManager;
  onClick: () => void;
}

function ContinueButtonContainer({ manager, onClick }: ContinueButtonContainerProps) {
  const currentStep = useCurrentStep(manager);
  const isCurrentStepValid = useIsStepValid(manager, currentStep.name);
  const total = useCheckoutTotal(manager);

  const label = useMemo(() => {
    if (currentStep.name !== 'review') {
      return 'Continue';
    }
    if (total.status === 'fetched' && total.price === 0) {
      return 'Finish';
    }
    return 'Continue to payment';
  }, [currentStep.name, total]);

  return (
    <RMButton background="primary" disabled={!isCurrentStepValid} onClick={onClick} fullWidth autoLoading>
      {label}
    </RMButton>
  );
}

interface InternalProjectCheckoutContainerProps {
  projectType: ProjectType;
}

function InternalProjectCheckoutContainer({ projectType }: InternalProjectCheckoutContainerProps) {
  // Services
  const { redirectService, checkoutAnalyticsService } = useServices();
  const navigate = useNavigate();
  const isMobile = useIsMobileViewport();

  // User state
  const user = useUser();
  const signOut = useSignOut();
  const userPersonQuery = usePersonQuery(user?.personId);
  const signedInUser = useMemo(() => {
    if (user == null) {
      return null;
    }

    const name = userPersonQuery?.data?.name ?? null;
    const email = user.communicationChannels.email;

    return {
      name,
      email,
    };
  }, [user, userPersonQuery?.data]);

  // Manager state
  const coupons = useMemo(() => getProjectCheckoutCoupons(user?.coupons), [user?.coupons]);
  const manager = useCreateProjectCheckoutManager(
    useMemo(
      () =>
        createProjectCheckoutForm({
          type: projectType,
          owner: {
            person: {
              firstName: userPersonQuery.data?.name?.first,
              lastName: userPersonQuery.data?.name?.last,
            },
            email: user?.communicationChannels.email,
            phone: user?.communicationChannels.phone,
          },
          gift: {
            message: `I'm gifting you Remento because your stories matter, and I want to preserve them for our family. Simply talk to share your memories - no writing needed! Each week, you'll receive questions and you’ll record your answers. Together, we'll create a life story book that captures your voice and memories for generations to come.`,
          },
          products: {
            books: projectType === 'FREE' ? 0 : 1,
            ebook: false,
          },
          referrerUserId: getQueryParam('referrer-user-id') ?? Cookies.get('referrer-user-id') ?? undefined,
          recipientPersonId: getQueryParam('recipient-person-id') ?? undefined,
          subscriptionOwnerPersonId: getQueryParam('subscription-owner-person-id') ?? undefined,
        }),
      [
        projectType,
        user?.communicationChannels.email,
        user?.communicationChannels.phone,
        userPersonQuery.data?.name?.first,
        userPersonQuery.data?.name?.last,
      ],
    ),
    coupons,
    PROJECT_CHECKOUT_STEPS[projectType],
  );
  const currentStep = useCurrentStep(manager);

  // Callbacks
  const goToSignIn = useCallback(async () => {
    const hostEmail = getInputValue(manager.form, 'owner.email');
    await redirectService.registerRedirect('signed-in', window.location.pathname + window.location.search);
    navigate(getSigninPath({ backupLocalData: true, defaultEmail: hostEmail }));
    removeToast(ConflictErrorType.USER_ALREADY_EXISTS);
  }, [manager.form, navigate, redirectService]);

  const handleGoToNextStep = useCallback(async () => {
    try {
      await goToNextStep(manager);
    } catch (error) {
      if (error instanceof ConflictError && error.data?.type === ConflictErrorType.USER_ALREADY_EXISTS) {
        toast(
          <span>
            It looks like this email is already associated with a Remento account. Please login{' '}
            <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={goToSignIn}>
              here
            </span>
            .
          </span>,
          'full-screen-toast',
          'error',
          { toastId: ConflictErrorType.USER_ALREADY_EXISTS, autoClose: 10000, closeOnClick: false },
        );
      } else if (error instanceof ConflictError && error.data?.type === ConflictErrorType.STORYTELLER_ALREADY_EXISTS) {
        toast(
          <RMText type="sans" size="xs" color="on-surface-primary">
            You have already purchased a book for this storyteller. You can create a free project for them{' '}
            <a onClick={() => navigate(getNewProjectPath())}>
              <RMText type="sans" size="xs" color="on-surface-primary" bold underline>
                here
              </RMText>
            </a>
            .
          </RMText>,
          'root-toast',
          'error',
          {
            autoClose: 10000,
          },
        );
        return;
      } else if (error instanceof ConflictError && error.data?.type === ConflictErrorType.STORYTELLER_SAME_USER) {
        toast(
          <RMText type="sans" size="xs" color="on-surface-primary">
            You and the gift recipient must have different email addresses.{' '}
            <a
              target="_blank"
              href="https://help.remento.co/en/articles/8634399-gifting-remento-to-multiple-people#h_e390000de4"
            >
              <RMText type="sans" size="xs" color="on-surface-primary" bold underline>
                Learn more.
              </RMText>
            </a>
          </RMText>,
          'root-toast',
          'error',
        );
        return;
      } else {
        toast('An unexpected error has occurred.', 'root-toast', 'error');
        captureException(error, true);
      }
    }
  }, [goToSignIn, manager, navigate]);

  const handleSignIn = useCallback(async () => {
    await redirectService.registerRedirect('signed-in', window.location.pathname + window.location.search);
    navigate(getSigninPath({ backupLocalData: true }));
  }, [navigate, redirectService]);

  // Analytics
  useEffect(() => {
    checkoutAnalyticsService.onCheckoutArrived(
      projectType === 'AUTOBIOGRAPHY' ? 'autobiography' : projectType === 'BIOGRAPHY' ? 'biography' : 'babybook',
    );
  }, [checkoutAnalyticsService, projectType]);

  // Shared components
  const Stepper = useMemo(
    () => (
      <RMStepper
        layout={isMobile ? 'progress' : 'stepper'}
        activeStepIndex={currentStep.index}
        hideIndex={projectType === 'FREE'}
        steps={manager.steps}
        onStepChange={(newStepIndex) => {
          goToStep(manager, manager.steps[newStepIndex].name);
        }}
      />
    ),
    [currentStep.index, isMobile, manager, projectType],
  );

  if (isMobile) {
    return (
      <ProjectCheckoutManagerProvider value={manager}>
        <ProjectCheckoutMobileLayout
          Stepper={Stepper}
          ContinueButton={<ContinueButtonContainer manager={manager} onClick={handleGoToNextStep} />}
          Header={currentStep.name !== 'review' ? <ProjectCheckoutSummaryContainer manager={manager} /> : null}
        >
          <Outlet />
        </ProjectCheckoutMobileLayout>
      </ProjectCheckoutManagerProvider>
    );
  }

  return (
    <ProjectCheckoutManagerProvider value={manager}>
      <ProjectCheckoutDesktopLayout
        Stepper={Stepper}
        ContinueButton={<ContinueButtonContainer manager={manager} onClick={handleGoToNextStep} />}
        RightPanel={<ProjectCheckoutSummaryContainer manager={manager} />}
        user={signedInUser}
        showPurchaseGiftCard={projectType !== 'FREE'}
        onSignIn={handleSignIn}
        onSignOut={signOut}
        onPurchaseGiftCard={() => window.open('https://remento.typeform.com/to/tgkWawHL', '_blank')}
      >
        <Outlet />
      </ProjectCheckoutDesktopLayout>
    </ProjectCheckoutManagerProvider>
  );
}

interface ProjectCheckoutContainerProps {
  projectType: ProjectType;
}

export function ProjectCheckoutContainer({ projectType }: ProjectCheckoutContainerProps) {
  return (
    <RementoPage type="empty">
      <ProjectCheckoutServicesProvider>
        <InternalProjectCheckoutContainer projectType={projectType} />
      </ProjectCheckoutServicesProvider>
    </RementoPage>
  );
}
