import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectType } from '@remento/types/project';
import Cookies from 'js-cookie';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMStepper } from '@/components/RMStepper/RMStepper';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { getQueryParam } from '@/hooks/useQueryParam';
import {
  AudienceTarget,
  ProjectCheckoutAudienceForm,
} from '@/modules/project-checkout/components/ProjectCheckoutAudienceForm/ProjectCheckoutAudienceForm';
import { ProjectCheckoutDesktopLayout } from '@/modules/project-checkout/components/ProjectCheckoutLayout/ProjectCheckoutDesktopLayout';
import { ProjectCheckoutMobileLayout } from '@/modules/project-checkout/components/ProjectCheckoutLayout/ProjectCheckoutMobileLayout';
import { ProjectCheckoutSummaryContainer } from '@/modules/project-checkout/containers/ProjectCheckoutSummary.container';
import { createProjectCheckoutForm } from '@/modules/project-checkout/project-checkout.form';
import { useCreateProjectCheckoutManager } from '@/modules/project-checkout/project-checkout.manager';
import { PROJECT_CHECKOUT_STEPS } from '@/modules/project-checkout/project-checkout.steps';
import { getProjectCheckoutCoupons } from '@/modules/project-checkout/project-checkout.utils';
import { ProjectCheckoutServicesProvider } from '@/modules/project-checkout/project-checkout-services.context.';
import {
  getCheckoutAudiencePath,
  getCheckoutAutobiographyContactPath,
  getCheckoutBabyHostPath,
  getCheckoutBiographyDeliveryPath,
  getSigninPath,
  RementoPage,
} from '@/modules/routing';
import { useServices } from '@/Services';
import { useSignOut, useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';

const BABYBOOK_GIFT_TYPEFORM = 'https://remento.typeform.com/to/fNVz3Nb4';

interface InternalProjectCheckoutAudiencePage {
  projectType: ProjectType;
}

function InternalProjectCheckoutAudiencePage({ projectType }: InternalProjectCheckoutAudiencePage) {
  const isMobile = useIsMobileViewport();

  const { redirectService, checkoutAnalyticsService } = useServices();
  const user = useUser();
  const navigate = useNavigate();
  const signOut = useSignOut();
  const [selectedAudienceTarget, setSelectedAudienceTarget] = useState(
    projectType === ProjectType.BIOGRAPHY ? AudienceTarget.SomeoneElse : AudienceTarget.Myself,
  );

  const personQuery = usePersonQuery(user?.personId);
  const signedInUser = useMemo(() => {
    const name = personQuery?.data?.name ?? null;
    const email = user?.communicationChannels.email ?? '';

    return {
      name,
      email,
    };
  }, [user, personQuery?.data]);

  const handleSignIn = useCallback(async () => {
    await redirectService.registerRedirect('signed-in', getCheckoutAudiencePath(projectType));
    navigate(getSigninPath({ backupLocalData: true }));
  }, [projectType, navigate, redirectService]);

  const title = useMemo(() => {
    if (projectType === ProjectType.BABY) {
      return 'I’d like to...';
    }

    return 'Who will be telling stories?';
  }, [projectType]);

  const audienceOptions = useMemo(() => {
    if (projectType === ProjectType.BABY) {
      return [
        { label: 'Create my own baby book', value: AudienceTarget.Myself },
        { label: 'Gift a baby book', value: AudienceTarget.SomeoneElse },
      ];
    }

    return [
      { label: 'Me', value: AudienceTarget.Myself },
      { label: 'A loved one', value: AudienceTarget.SomeoneElse },
    ];
  }, [projectType]);

  // In this page. the manager is only used to show the summary.
  // Another instance will be created in the next step.
  const coupons = useMemo(() => getProjectCheckoutCoupons(user?.coupons), [user?.coupons]);
  const manager = useCreateProjectCheckoutManager(
    useMemo(
      () =>
        createProjectCheckoutForm({
          products: { books: 1 },
          type: projectType === ProjectType.BABY ? 'BABYBOOK' : 'BIOGRAPHY',
          referrerUserId: getQueryParam('referrer-user-id') ?? Cookies.get('referrer-user-id') ?? undefined,
        }),
      [projectType],
    ),
    coupons,
    PROJECT_CHECKOUT_STEPS[projectType === ProjectType.BABY ? 'BABYBOOK' : 'BIOGRAPHY'],
  );

  const handleGoToNextStep = useCallback(async () => {
    checkoutAnalyticsService.onCheckoutPersonalized(selectedAudienceTarget);

    let nextStep: string;
    switch (projectType) {
      case ProjectType.BIOGRAPHY:
        nextStep =
          selectedAudienceTarget === AudienceTarget.SomeoneElse
            ? getCheckoutBiographyDeliveryPath()
            : getCheckoutAutobiographyContactPath();
        break;

      case ProjectType.BABY: {
        if (selectedAudienceTarget === AudienceTarget.SomeoneElse) {
          window.open(BABYBOOK_GIFT_TYPEFORM, '_blank');
          return;
        } else {
          nextStep = getCheckoutBabyHostPath();
        }
        break;
      }

      default:
        throw new Error(`The ${projectType} project is not implemented.`);
    }

    navigate(nextStep + window.location.search);
  }, [checkoutAnalyticsService, navigate, projectType, selectedAudienceTarget]);

  const handlePurchaseGiftCard = useCallback(() => {
    switch (projectType) {
      case ProjectType.BIOGRAPHY:
        window.open('https://remento.typeform.com/to/tgkWawHL', '_blank');
        break;

      case ProjectType.BABY:
        window.open(BABYBOOK_GIFT_TYPEFORM, '_blank');
        break;

      default:
        throw new Error(`The ${projectType} project is not implemented.`);
    }
  }, [projectType]);

  const checkoutAudienceForm = (
    <ProjectCheckoutAudienceForm
      selectedValue={selectedAudienceTarget}
      onSelectedChange={(selected: AudienceTarget) => setSelectedAudienceTarget(selected)}
      user={user ? signedInUser : null}
      title={title}
      audienceOptions={audienceOptions}
      onSignIn={handleSignIn}
      onSignOut={signOut}
      onPurchaseGiftCard={projectType !== ProjectType.BABY ? handlePurchaseGiftCard : null}
    />
  );

  const steps = useMemo(() => {
    if (projectType === ProjectType.BABY) {
      return [
        { label: 'Project type' },
        { label: 'Your profile', disabled: true },
        { label: 'Book details', disabled: true },
        { label: 'Review', disabled: true },
      ];
    }

    if (selectedAudienceTarget === AudienceTarget.Myself) {
      return [{ label: 'Storyteller' }, { label: 'Details', disabled: true }, { label: 'Review', disabled: true }];
    }
    return [
      { label: 'Storyteller' },
      { label: 'Delivery', disabled: true },
      { label: 'Details', disabled: true },
      { label: 'Review', disabled: true },
    ];
  }, [projectType, selectedAudienceTarget]);

  const Stepper = useMemo(
    () => <RMStepper layout={isMobile ? 'progress' : 'stepper'} activeStepIndex={0} steps={steps} />,
    [isMobile, steps],
  );

  // Analytics
  useEffect(() => {
    checkoutAnalyticsService.onCheckoutArrived('book');
  }, [checkoutAnalyticsService]);

  if (isMobile) {
    return (
      <ProjectCheckoutMobileLayout
        Stepper={Stepper}
        ContinueButton={
          <RMButton background="primary" onClick={handleGoToNextStep} fullWidth autoLoading>
            Continue
          </RMButton>
        }
        Header={<ProjectCheckoutSummaryContainer manager={manager} />}
      >
        {checkoutAudienceForm}
      </ProjectCheckoutMobileLayout>
    );
  }

  return (
    <ProjectCheckoutDesktopLayout
      Stepper={Stepper}
      ContinueButton={
        <RMButton background="primary" onClick={handleGoToNextStep} fullWidth autoLoading>
          Continue
        </RMButton>
      }
      RightPanel={<ProjectCheckoutSummaryContainer manager={manager} />}
      user={user ? signedInUser : null}
      showPurchaseGiftCard={projectType !== ProjectType.BABY}
      onSignIn={handleSignIn}
      onSignOut={signOut}
      onPurchaseGiftCard={handlePurchaseGiftCard}
    >
      {checkoutAudienceForm}
    </ProjectCheckoutDesktopLayout>
  );
}

export function ProjectCheckoutAudiencePage() {
  const params = useParams();
  const projectType = params.project === 'baby' ? ProjectType.BABY : ProjectType.BIOGRAPHY;

  return (
    <RementoPage type="empty" pageAnalyticsPayload={{ projectType }}>
      <ProjectCheckoutServicesProvider>
        <InternalProjectCheckoutAudiencePage projectType={projectType} />
      </ProjectCheckoutServicesProvider>
    </RementoPage>
  );
}
